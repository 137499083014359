import React from 'react';

export const QuoteIconCircle = () => (
  <svg
    width="119"
    height="119"
    viewBox="0 0 119 119"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.1152 118.23C91.7637 118.23 118.23 91.7637 118.23 59.1152C118.23 26.4668 91.7637 0 59.1152 0C26.4668 0 0 26.4668 0 59.1152C0 91.7637 26.4668 118.23 59.1152 118.23ZM29.5844 61.739C29.5844 73.0262 35.8403 78.254 43.1198 78.2539C51.2525 78.2539 55.5178 71.6599 55.4609 63.7588C55.4609 53.3034 48.0108 50.7489 43.4042 50.3925C43.063 47.6004 46.4184 42.7885 50.5131 39.9964L49.0345 35.6003C41.9824 36.5508 29.5844 45.8776 29.5844 61.739ZM60.4656 61.739C60.4656 73.0262 66.7215 78.2539 74.001 78.2539C82.1337 78.2539 86.4559 71.6599 86.4559 63.7588C86.4559 53.3034 78.9489 50.7489 74.3991 50.3925C74.001 47.541 77.3565 42.7885 81.3943 39.9964L79.9157 35.6003C72.8636 36.5508 60.4656 45.8776 60.4656 61.739Z"
      fill="white"
    />
  </svg>
);
