import styled from 'styled-components';
import { atMinWidth } from '../../../../styles/atoms/breakpoints';
import { colors } from '../../../../styles/atoms/colors';
import { font } from '../../../../styles/atoms/typography';

export const QuoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
`;

export const StarRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const HeadingRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
`;

export const QuoteContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 32px;
  color: ${colors.gray[900]};

  .review-headline {
    ${font('text', 'lg', 700)};

    ${atMinWidth.sm`
      ${font('text', 'xl', 700)};
    `};
  }

  .review-date {
    ${font('text', 'sm', 400)};

    ${atMinWidth.sm`
      ${font('text', 'md', 400)};
    `}
  }

  &&&.message p {
    margin: 0;
    ${font('text', 'md', 400)};
    color: ${colors.gray[700]};

    ${atMinWidth.sm`
      ${font('text', 'lg', 400)};
    `}
  }
`;
