import React from 'react';

import StarList from '../../Reviews/ReviewComponents/StarList/StarList';

import { QuoteIconCircle } from '../../../images/Hero/QuoteIconCircle';

import {
  HeroReviewContainer,
  ReviewAuthorBold,
  QuoteSvgDiv,
  HeroReviewWrapper,
} from '../styles/HeroReviewCard.styles';
import { StarRow } from '../../Reviews/ReviewComponents/QuoteSection/QuoteSection.styles';

const HeroReviewCard = ({ testimonial }) => {
  const { person, starCount, body, headline } = testimonial;

  const personRole = person.role;
  const companyName = person.company.name;
  const personName = `${person.firstName} ${person.lastName}`;

  return (
    <HeroReviewWrapper>
      <HeroReviewContainer>
        <QuoteSvgDiv>
          <QuoteIconCircle />
        </QuoteSvgDiv>
        <StarRow>
          <StarList starCount={starCount} />
        </StarRow>
        <div className="review-headline">{headline}</div>
        <div
          className="review-body"
          dangerouslySetInnerHTML={{ __html: body }}
        />
        <ReviewAuthorBold>
          <div className="text-bold">{personName.concat(',')}</div>
          <div className="text-light">{personRole}</div>
          <div className="text-light"> at {companyName}</div>
        </ReviewAuthorBold>
      </HeroReviewContainer>
    </HeroReviewWrapper>
  );
};

export default HeroReviewCard;
