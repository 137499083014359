import styled from 'styled-components';
import { font } from '../../../styles/atoms/typography';
import { colors } from '../../../styles/atoms/colors';
import { atMinWidth } from '../../../styles/atoms/breakpoints';

export const HeroReviewWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  ${atMinWidth.sm`
    padding-bottom: 4rem;
  `}

  ${atMinWidth.lg`
    padding: 0;
  `}
`;

export const HeroReviewContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 480px;
  z-index: 1;
  background: linear-gradient(
    69.06deg,
    rgba(116, 137, 255, 1) -116.58%,
    rgba(0, 31, 204, 1) 101.18%
  );
  padding: 1rem;
  gap: 19.3px;
  border: 1px solid ${colors.base.white};
  border-radius: 10px;

  ${atMinWidth.md`
    padding: 2.5rem;
  `}

  .review-headline {
    white-space: unset;
    color: ${colors.base.white};
    ${font('display', 'md', '700')};
  }

  && {
    .review-body p {
      margin: 0;
      ${font('text', 'sm', '400')}
      color: ${colors.base.white};

      ${atMinWidth.md`
        ${font('text', 'lg', '400')}
      `}
    }
  }
`;

export const QuoteSvgDiv = styled.div`
  transform: scale(0.6);
  top: -36px;
  right: -36px;
  max-width: 100%;
  position: absolute;

  ${atMinWidth.md`
      transform: scale(0.8);
      ${font('text', 'lg', 700)};
  `}
`;

export const ReviewAuthorBold = styled.div`
  gap: 4px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .text-bold {
    white-space: nowrap;
    color: ${colors.base.white};

    ${font('text', 'sm', 700)};

    ${atMinWidth.md`
      ${font('text', 'md', 700)};
    `}

    ${atMinWidth.lg`
      ${font('text', 'lg', 700)};
    `}
  }

  .text-light {
    color: white;
    white-space: nowrap;
    ${font('text', 'xs', 400)};

    ${atMinWidth.md`
      ${font('text', 'sm', 400)};
    `}

    ${atMinWidth.lg`
      ${font('text', 'md', 400)};
    `}
  }
`;
